/*------------------------------------*
  Base styles
*------------------------------------*/

html {
  scroll-behavior: smooth;

  @media (max-width: 25em) {
    font-size: 14px;
  }
}

body {
  @apply type-base text-dark;
}

::selection {
  color: theme('colors.orange');
  background-color: theme('colors.dark');
}

@media (prefers-reduced-motion) {
  html {
    scroll-behavior: unset;
  }

  *,
  *::before,
  *::after {
    transition: none !important;
  }
}

.container {
  margin: 0 8vw;

  @screen s {
    margin: 0 12vw;
  }
}

svg {
  width: 100%;
  height: inherit;
}

.logo {
  display: inline-block;
  max-width: 50px;

  svg {
    display: block;
    width: 100%;
  }

  @screen s {
    width: theme('spacing.20');
    max-width: none;
  }
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link {
  position: relative;
  display: inline-block;

  &:before {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    opacity: 0.7;
    transform: scaleX(0);
    transform-origin: top right;
    transition: 0.3s transform theme('transitionTimingFunction.out-quad');
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: top left;
      transition: 0.3s transform theme('transitionTimingFunction.out-quad');
    }
  }
}

.nav {
  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: theme('colors.dark');
    transform: translateY(-100%);
    transition: 0.6s transform theme('transitionTimingFunction.out-quad');
  }
}

.scrolled {
  .nav:before {
    transform: translateY(0);
    transition: 0.6s transform theme('transitionTimingFunction.out-quad');
  }
}
