@tailwind base;
@import 'base';

@tailwind components;
@import 'components/youtube-player';
@import 'components/slider';

@tailwind utilities;

@import './components/footer';

@import 'pages/terms';
