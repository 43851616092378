/*------------------------------------*
  Youtube Player
*------------------------------------*/
.youtube-player {
  iframe {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: 0.6s opacity theme('transitionTimingFunction.out-quad');
  }

  &.is-playing {
    iframe {
      pointer-events: all;
      opacity: 1;
      transition: 0.6s opacity theme('transitionTimingFunction.out-quad');
    }

    button {
      pointer-events: none;
    }
  }
}

.youtube-player__play {
  opacity: 0;
  transform-origin: bottom left;

  svg {
    max-width: 67px;
    margin-top: 12rem;
    color: theme('colors.orange');

    @screen s {
      max-width: 98px;
      margin-top: 0;
    }
  }

  polygon {
    transform-origin: center center;
    transition: 0.3s transform theme('transitionTimingFunction.out-quad');
  }

  &:hover {
    polygon {
      transform: scale(1.5);
      transition: 0.3s transform theme('transitionTimingFunction.out-quad');
    }
  }
}

// @note translateY to 0 on YoutubePlayer component Load.
.youtube-player__title {
  display: inline-block;
  transform: translateY(-100%);
}
