/*------------------------------------*
  Slider
*------------------------------------*/

.slide {
  opacity: 0;
  transition: 1.2s opacity theme('transitionTimingFunction.out-quad');

  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.5);
  }

  &.is-active {
    opacity: 1;
    transition: 1.2s opacity theme('transitionTimingFunction.in-quad');
  }

  @screen s {
    &:before {
      content: unset;
    }
  }
}

.slider__nav {
  width: 12px;

  &:focus {
    outline: none;
    fill: white;
  }

  svg {
    width: 100%;
  }

  @screen xs {
    width: 18px;
  }
}
